<template>
  <div class="detailP-quick-box">
    <div class="icon-box" >
      <div class="circle-box" @click="toggleLike(item.isLiked)" :class="{ active: item.isLiked }">
        <div class="icon-img icon-thumb"></div>
        <div class="tooltip">추천</div>
      </div>
      <div class="count">{{ item.likeCnt }}</div>
    </div>

    <div class="horizon-bar"></div>

    <div class="icon-box">
      <div class="circle-box" @click="focusComments">
        <div class="icon-img icon-quick-comment"></div>
        <div class="tooltip">댓글</div>
      </div>
      <div class="count">{{ item.commentCount }}</div>
    </div>

    <div class="icon-box" >
      <div class="circle-box" @click="toggleSharePopup" v-click-outside="closeSharePopup">
        <div class="icon-img icon-share"></div>
        <div class="tooltip">공유</div>
      </div>
      <div class="count">{{ item.share }}</div>
      <div v-show="isShowSharePopup" class="share-box">
        <span id="kakaoShare" class="share-btn cursor-pointer" @click="shareKakao(item, src)"><img src="@/assets/img/common/share_kakao.png"></span>
        <span class="share-btn cursor-pointer" @click="shareNaverBlog(item)"><img src="@/assets/img/common/share_blog.png"></span>
        <span class="share-btn cursor-pointer" @click="shareBand(item)"><img src="@/assets/img/common/share_band.png"></span>
        <span class="share-btn cursor-pointer" @click="shareFacebook(item)"><img src="@/assets/img/common/share_facebook.png"></span>
        <span class="share-btn cursor-pointer" @click="shareUrl"><img src="@/assets/img/common/share_url.png"></span>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import shareMixin from "@/mixins/shareMixin"
export default {
  name: "quickBox",
  props: ['cateCode', 'item', 'src'],
  mixins: [shareMixin],
  data() {
    return {
      isShowSharePopup: false,
    }
  },
  computed: {
    ...mapGetters({
      isLogin: 'session/isLogin',
    }),
  },
  methods: {
    async toggleLike(isLike) {
      if (!this.isLogin) {
        this.goLoginPopup()
        return false
      }
      const params = {
        cateCode: this.cateCode,
        boIdx: this.item.boIdx,
        isLike: !isLike,
      }
      const { result } = await this.$api.toggleLike(params)
      if (result === 'success') {
        this.item.isLiked = params.isLike
        this.item.likeCnt = params.isLike ? Number(this.item.likeCnt)+1 : Number(this.item.likeCnt)-1
      }
    },
    focusComments() {
      this.$parent.$refs.comments.$refs.commentSaveBtn.focus()
    },
    toggleSharePopup() {
      this.isShowSharePopup = !this.isShowSharePopup
    },
    closeSharePopup() {
      this.isShowSharePopup = false
    },
    goLoginPopup() {
      this.setMsgPopup({
        type: 'alert',
        message: this.$msg('login.need'),
        okay: 'GO_LOGIN',
        okayCallback: () => {
          this.setMsgPopup()
          localStorage.setItem('loginBeforePage', this.$route.fullPath)
          this.$router.push('/login')
        }
      })
    },
  }
}
</script>
