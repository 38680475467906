import {mapActions} from "vuex"

export default {
  methods: {
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    getCurrentUrl(isSeo) {
      if (process.env.NODE_ENV === 'production') {
        if (isSeo) return `https://ggolab.co.kr${location.pathname}`
        else       return `https://www.ggolab.co.kr${location.pathname}`
      }
      if (isSeo) return `https://ggolab-seo.programrush.co.kr${location.pathname}`
      else       return `https://ggolab.programrush.co.kr${location.pathname}`
    },
    async shareClick(type) {
      const { result } = await this.$api.share({
        cateCode: this.cateCode,
        type: type,
        boIdx: this.item.boIdx
      })
      if (result === 'success') {
        this.item.share = Number(this.item.share)+1
      }
    },
    shareKakao(item, src) {
      const url = this.getCurrentUrl(false)
      Kakao.Link.sendDefault({
        objectType: 'feed',
        content: {
          title: item.title.toString(),
          description: item.contentText.toString(),
          imageUrl: item.src ? item.src : src ? src : '',
          link: {
            webUrl: url,
            mobileWebUrl: url
          },
        },
        buttons: [
          {
            title: '웹으로 보기',
            link: {
              webUrl: url,
              mobileWebUrl: url
            },
          },
        ],
      })
      this.shareClick('kakao').then()
    },
    shareNaverBlog(item) {
      let url = this.getCurrentUrl(true)
      const shareUrl = `https://blog.naver.com/openapi/share?url=${encodeURIComponent(url)}`
      window.open(shareUrl, '_sns_share')
      this.shareClick('blog').then()
    },
    shareBand(item) {
      let url = this.getCurrentUrl(true)
      const shareUrl = `https://band.us/plugin/share?body=${encodeURIComponent(url)}&route=${encodeURIComponent(url)}`;
      window.open(shareUrl, '_sns_share')
      this.shareClick('band').then()
    },
    shareFacebook(item) {
      let url = this.getCurrentUrl(true)
      FB.ui({
        method: 'share',
        href: url,
      }, function(response){});
      this.shareClick('facebook').then()
    },
    shareUrl() {
      if (this.$isIE()) {
        window.clipboardData.setData("Text", window.location.href);
      }
      else {
        let tempElem = document.createElement('textarea');
        tempElem.value = window.location.href;
        document.body.appendChild(tempElem);
        tempElem.select();
        document.execCommand("copy");
        document.body.removeChild(tempElem);
      }
      this.alertPopup(this.$msg('clipboard.paste'))
    },
    alertPopup(msg) {
      this.setMsgPopup({
        type: 'alert',
        message: msg,
        okay: 'alert',
        okayCallback: () => {
          this.setMsgPopup()
        }
      })
    },
  },
}
